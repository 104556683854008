import http from "../http-common";

class Service {
    getMessages(channelIds, skip, limit){
        return http.get(`/messages/messages/${channelIds.join(",")}/${skip}/${limit}`);
    }
    getAttachment(message){
        return http.get(`/messages/attachment/${message.channel}/${message._id}`,
            {responseType: 'blob'});
    }
    getNumberOfUnreadMessages(channelIds){
        return http.get(`/messages/numberOfUnreadMessages/${channelIds.join(",")}`);
    }
    post(channelId, content, supervision, expectSupervision, attachmentFile, contextInfo){
        const formData = new FormData();
        formData.append('content', content);
        formData.append('supervision', supervision);
        formData.append('expectSupervision', expectSupervision);
        formData.append('attachment', attachmentFile);
        formData.append('contextInfo', JSON.stringify(contextInfo));
        return http.post(`/messages/${channelId}`, formData,
            {headers: { 'Content-Type': 'multipart/form-data' }});
    }
    setEmpty(message){
        return http.put(`/messages/empty/${message.channel}/${message._id}`);
    }
}

export const MessageService = new Service();

